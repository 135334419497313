import React, { useState, useEffect } from 'react'
import * as JitisiMeetAPI from '../api/jitsiMeet.api'
import { openNotificationWithIcon } from '../utils'

const JitsiMeetContext = React.createContext({})

function JitsiMeetProvider(props) {
	const [meetingInfo, setMeetingInfo] = useState({})
	const [meetingId, setMeetingId] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isLoader, setLoader] = useState(false)

	const [openBackDrop, setOpenBackDrop] = useState(false)
	const [showIssueButton, setShowIssueButton] = useState(false);
	const [startPaidTimerCompIsLoaded, setStartPaidTimerCompIsLoaded] = useState(false)

	const initialBackDropValue = {
		jobId: '',
		isBackdropVisible: false,
	}

	const [isBackDropOpen, setIsBackDropOpen] = useState(() => {
		const storedData = localStorage.getItem('isBackdropVisible')
		return storedData ? JSON.parse(storedData) : initialBackDropValue
	})

	const handleBackDropChange = (key, value) => {
		setIsBackDropOpen((prevFormData) => ({
			...prevFormData,
			[key]: value,
		}))
	}

	useEffect(() => {
		console.log('customerModalAction :: :: :', isBackDropOpen)
		const formDataString = JSON.stringify(isBackDropOpen)
		localStorage.setItem('isBackdropVisible', formDataString)
	}, [isBackDropOpen])

	// useEffect(() => {
	// 	const { jobId, isBackdropVisible } = isBackDropOpen;
	// 	console.log('startPaidTimerCompIsLoaded :: :', startPaidTimerCompIsLoaded)
	// 	if (!isBackdropVisible && !startPaidTimerCompIsLoaded) {
	// 		handleBackDropChange('jobId', '')
	// 		handleBackDropChange('isBackdropVisible', false)
	// 	}
	// }, [startPaidTimerCompIsLoaded,isBackDropOpen])

	const getJitsiMeet = async (meetingId) => {
		try {
			setIsLoading(true)

			const res = await JitisiMeetAPI.gettingMeeting(meetingId)

			setMeetingInfo(res)
			setIsLoading(false)
		} catch (err) {
			setIsLoading(false)
			openNotificationWithIcon('error', 'Error', 'Job does not exist.')
		}
	}

	async function createMeeting(data) {
		try {
			setIsLoading(true)
			const res = await JitisiMeetAPI.createMeeting(data)

			setMeetingId(res.id)
			setIsLoading(true)
			return res
		} catch (err) {
			setIsLoading(true)
			openNotificationWithIcon('error', 'Error', 'Job does not exist.')
		}
	}

	return (
		<JitsiMeetContext.Provider
			value={{
				meetingId,
				meetingInfo,
				isLoading,
				getJitsiMeet,
				createMeeting,
				openBackDrop,
				setOpenBackDrop,
				startPaidTimerCompIsLoaded,
				setStartPaidTimerCompIsLoaded,
				handleBackDropChange,
				isBackDropOpen,
				showIssueButton, 
				setShowIssueButton,
				isLoader, 
				setLoader
			}}
			{...props}
		/>
	)
}

function useJitsiMeet() {
	const context = React.useContext(JitsiMeetContext)
	if (context === undefined) {
		throw new Error('useJitsiMeet must be used within a JobProvider')
	}
	return context
}

export { JitsiMeetProvider, useJitsiMeet }
