import React, { createContext, useState, useContext, useEffect } from 'react';

const VOLUME_STORAGE_KEY = 'notificationVolume';
const VOLUME_MUTED_KEY = 'notificationMuted';

const VolumeContext = createContext();

export const VolumeProvider = ({ children }) => {
  const [volume, setVolume] = useState(() => {
    const storedVolume = localStorage.getItem(VOLUME_STORAGE_KEY);
    return storedVolume ? parseFloat(storedVolume) : 1;
  });
  const [isMuted, setIsMuted] = useState(() => {
    const storedMuted = localStorage.getItem(VOLUME_MUTED_KEY);
    return storedMuted ? storedMuted === 'true' : false;
  });

  useEffect(() => {
    localStorage.setItem(VOLUME_STORAGE_KEY, volume);
  }, [volume]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    localStorage.setItem(VOLUME_MUTED_KEY, !isMuted);
  };

  const contextValue = {
    volume,
    setVolume,
    isMuted,
    toggleMute,
    effectiveVolume: isMuted ? 0 : volume
  };

  return (
    <VolumeContext.Provider value={contextValue}>
      {children}
    </VolumeContext.Provider>
  );
};

export const useVolume = () => {
  const context = useContext(VolumeContext);
  if (context === undefined) {
    throw new Error('useVolume must be used within a VolumeProvider');
  }
  return context;
};

// Make sure to export VolumeContext if needed elsewhere
export { VolumeContext };